export const reviews = [
  {
    "id": 1,
    "user": {
      "id": 12,
      "name": `Isaac`
    },
    "rating": 3.6,
    "comment": `Unfortunately we don't have a reliable way to tell the true ratings of a movie.`,
    "date": `2021-03-19T13:11:03.998Z`
  },
  {
    "id": 2,
    "user": {
      "id": 17,
      "name": `Emely`
    },
    "rating": 4.5,
    "comment": `I love this movie. This film is a milestone in cinematography. Great Immersive camera-work. This film is an experience and i has already seen it 4 times and I only see more quality of the film.`,
    "date": `2021-03-05T13:11:03.999Z`
  },
  {
    "id": 3,
    "user": {
      "id": 11,
      "name": `Jack`
    },
    "rating": 4.7,
    "comment": `The editing is a mess, and the transitions of the plot or characters are rather strange. There is no narrative focus and the storytelling is unbalanced. I cannot really understand why such a bad movie received an overwhelming approval from the critics. `,
    "date": `2021-03-21T13:11:03.999Z`
  }
];

export const review = [
  {
    "id": 1,
    "user": {
      "id": 12,
      "name": `Isaac`
    },
    "rating": 3.6,
    "comment": `Unfortunately we don't have a reliable way to tell the true ratings of a movie.`,
    "date": `2021-03-19T13:11:03.998Z`
  },
];
